<template>
  <div>
    <Navbar></Navbar>
    <div class="container-fluid cf">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h3>Inventario</h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row div_table">
            <div class="col">
              <div class="table-responsive">
                <DataTable class="table table-striped" :columns="columns" :data="items" :options="options">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nro</th>
                      <th>Total</th>
                      <th>Usuario</th>
                      <th>Licencia</th>
                      <th>Fecha Reserva</th>
                      <th>Fecha vuelo</th>
                      <th>Llegada al parqueadero</th>
                      <th>Fecha de Salida</th>
                      <th>Personas</th>
                      <th>Maletas</th>
                      <th>Necesidades</th>
                      <th>Servicios</th>
                      <th>Tipo de Parqueadero</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoaderComponent v-if="isLoading" />
  </div>
</template>

<script>
import Navbar from '../utils/Navbar.vue';
import LoaderComponent from '../utils/LoaderComponent.vue';
import Toast from '@/services/SweetAlertService';
import Api from '@/services/apiService.js';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
DataTable.use(DataTablesCore);

export default {
  name: 'ReservasComponent',
  components: {
    Navbar,
    LoaderComponent,
    DataTable
  },
  data() {
    return {
      isLoading: true,
      // modal
      btn_submit: false,
      title: 'Historial de cambios',
      modal: '',
      // form
      id: '',
      name: '',
      // datatable
      items: [],
      items_h: [],
      services: [],
      notification: [],
      columns: [
        {
          width: '10rem',
          data: 'id',
          render: function (data, type, row, index) {
            return index.row + 1;
          }
        },
        {
          data: 'nro'
        },
        {
          data: 'total',
          className: 'nowrap',
          render: function (data, type, row) {
            let totals = '';
            if (row.difference == 0) {
              const formattedPrice = parseFloat(data).toFixed(2);
              const parts = formattedPrice.split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              totals += '$' + parts.join('.');
            } else {
              const formattedPrice = parseFloat(data - row.difference).toFixed(2);
              const parts = formattedPrice.split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              totals += '<span style="color: #94c126;">$' + parts.join(".") + '</span>';
              const formattedPriceD = parseFloat(row.difference).toFixed(2);
              const partsD = formattedPriceD.split('.');
              partsD[0] = partsD[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              totals += ' <span style="color: #dc3545;">$' + partsD.join(".") + '</span>';
            }
            return totals;
          }
        },
        {
          data: 'user',
          render: function (data) {
            return data.name + " " + data.lastname;
          }
        },
        {
          data: 'license'
        },
        {
          className: 'nowrap',
          data: 'created_at',
          render: function (data) {
            const date = new Date(data);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          }
        },
        {
          className: 'nowrap',
          data: 'flight_date',
          render: function (data, type, row) {
            return data + " " + row.flight_time;
          }
        },
        {
          data: 'arrival_parking',
        },
        {
          className: 'nowrap',
          data: 'arrival_date',
          render: function (data, type, row) {
            return data + " " + row.arrival_time;
          }
        },
        {
          data: 'peoples',
        },
        {
          data: 'suitcases',
        },
        {
          data: 'special_needs',
        },
        {
          className: 'nowrap',
          data: 'service_id',
        },
        {
          data: 'parking_type_name',
        },
        {
          className: 'nowrap',
          data: 'status',
          render: function (data) {
            if (data == 0) {
              return '<span class="badge rounded-pill bg-danger">Cancelada</span>';
            } else if (data == 1) {
              return '<span class="badge rounded-pill bg-success">Activa</span>';
            } else if (data == 2) {
              return '<span class="badge rounded-pill bg-primary">Finalizada</span>';
            }
          }
        }
      ],
      options: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.6/i18n/es-ES.json',
        },
        responsive: true,
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      this.isLoading = true;
      try {
        const response = await Api.getData('get_today_reserations', {});
        this.isLoading = false;
        if (response.response) {
          this.items = response.data;
          this.services = response.services;

          this.items.forEach(item => {
            let list = "<ul>";
            if (item.service_id) {
              let services = item.service_id.split(',');
              services.forEach(service => {
                let name = this.services.filter(serv => serv.id == service);
                if (name.length > 0) {
                  list += "<li>" + name[0].name + "</li>"
                }
              });
            }
            list += "</ul>";
            item.service_id = list;
          });
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    
    
  }
}
</script>

<style scoped>
@import 'bootstrap';
@import 'datatables.net-dt';
@import 'datatables.net-bs5';

.card-body {
  background: #fff;
}

.cf {
  margin-top: 6rem !important;
}

.notification{
  border: 1px solid #e9e9e9;
  padding: 1rem;
}
.toast-body{
  cursor: pointer;
}
.nactive {
  background: #e9e9e9;
}

.nowrap {
  white-space: nowrap !important;
}
</style>