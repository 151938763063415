<template>
  <div>
    <Navbar></Navbar>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <!-- Indicadores de vehículos -->
          <div class="row indicator-container">
            <!-- Indicador de Parqueadero Cubierto -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ind">
              <div class="indicator">
                <h1>{{ coveredVehicles }}</h1>
              </div>
              <div class="indicator-label">Vehículos en Parqueadero Cubierto</div>
            </div>
            <!-- Indicador de Parqueadero al Aire Libre -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ind">
              <div class="indicator">
                <h1>{{ outdoorVehicles }}</h1>
              </div>
              <div class="indicator-label">Vehículos en Parqueadero al Aire Libre</div>
            </div>
          </div>
          <div class="row chart-container">
            <div class="col">
              <BarChart :data="dataBar" />
            </div>
          </div>
          <div class="row chart-container">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <PieChart :data="dataPie" />
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6">
              <LineChart :data="dataLine" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoaderComponent v-if="isLoading" />
  </div>
</template>

<script>
import Navbar from './utils/Navbar.vue';
import LoaderComponent from './utils/LoaderComponent.vue';
import Toast from '@/services/SweetAlertService';
import Api from '@/services/apiService.js';
import BarChart from './utils/charts/BarChart.vue'
import PieChart from './utils/charts/PieChart.vue'
import LineChart from './utils/charts/LineChart.vue'
export default {
  name: 'DashboardComponent',
  components: {
    Navbar,
    LoaderComponent,
    BarChart,
    PieChart,
    LineChart
  },
  data() {
    return {
      isLoading: true,
      coveredVehicles: 0, // Vehículos en parqueadero cubierto
      outdoorVehicles: 0, // Vehículos en parqueadero al aire libre
      dataBar: [],
      dataPie: [],
      dataLine: [],
    };
  },
  mounted() {
    this.getParkingData();
    this.get();
  },
  methods: {
    async getParkingData() {
      try {
        // Obtener el número de vehículos en parqueadero cubierto
        const coveredResponse = await Api.postData('indicator_parking_covered', {});
        if (coveredResponse.response) {
          this.coveredVehicles = coveredResponse.total;
        } else {
          Toast.toast('Error al obtener vehículos cubiertos', 'error');
        }

        // Obtener el número de vehículos en parqueadero al aire libre
        const outdoorResponse = await Api.postData('indicator_parking_outdoors', {});
        if (outdoorResponse.response) {
          this.outdoorVehicles = outdoorResponse.total;
        } else {
          Toast.toast('Error al obtener vehículos al aire libre', 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrió un error: ' + error, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async get() {
      this.isLoading = true;
      try {
        const response = await Api.getData('dashboard', {});
        this.isLoading = false;
        if (response.response) {
          this.dataBar = response.reservasPorMesArray;
          this.dataPie = response.administration;
          this.dataLine = response.reservasPorTipoArray;
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
  }
}
</script>

<style scoped>
/* Estilo del contenedor de los indicadores */
.indicator-container {
  margin-bottom: 30px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

/* Estilo de cada indicador individual */
.ind {
  text-align: center;
  margin-bottom: 10px;
}

.indicator {
  background-color: #94c125; /* Color de fondo (azul claro) */
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

/* Efecto hover en el indicador */
.indicator:hover {
  transform: scale(1.05);
}

.indicator h1 {
  color: white; /* Color del número */
  font-size: 4rem; /* Tamaño del número */
  margin: 0;
  font-weight: 700;
}

/* Estilo para el label debajo del indicador */
.indicator-label {
  font-size: 1.3rem;
  color: #2c3e50; /* Color del label (gris oscuro) */
  font-weight: 600;
  margin-top: 5px;
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

/* Separación antes del BarChart */
.chart-container {
  margin-top: 30px;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .indicator h1 {
    font-size: 2.5rem; /* Reducir tamaño del número en pantallas pequeñas */
  }

  .indicator-container {
    flex-direction: column;
    align-items: center;
  }
}
</style>