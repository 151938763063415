<template>
    <div>
      <Navbar></Navbar>
      <div class="container-fluid cf">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h3>Reporte - Lavado de Vehículos</h3>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Filtros para fecha de salida y hora de salida -->
            <div class="row mb-3">
                <div class="col-md-5">
                <label for="filterDate"><b>Fecha de Salida :</b></label>
                <input
                  type="date"
                  id="filterDate"
                  v-model="filterDate"
                  class="form-control"
                  @change="applyFilters"
                />
                </div>
                <div class="col-md-5">
                <label for="filterTime"><b>Hora de Salida :</b></label>
                <input
                  type="time"
                  id="filterTime"
                  v-model="filterTime"
                  class="form-control"
                  @change="applyFilters"
                />
                </div>
                <div class="col-md-2 d-flex align-items-end">
                <button class="btn btn-primary" @click="sendFilters">Buscar</button>
                </div>
            </div>
            <div class="row div_table">
              <div class="col">
                <div class="table-responsive">
                  <DataTable id="reporte" class="table table-striped" :columns="columns" :data="filteredItems" :options="options" :key="items.length">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Licencia</th>
                        <th>Fecha de Reserva</th>
                        <th>Fecha de Salida</th>
                        <th>Hora de Salida</th>
                        <th>Servicios</th>
                      </tr>
                    </thead>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoaderComponent v-if="isLoading" />
    </div>
  </template>
  
  <script>
  import Navbar from '../utils/Navbar.vue';
  import LoaderComponent from '../utils/LoaderComponent.vue';
  import Toast from '@/services/SweetAlertService';
  import Api from '@/services/apiService.js';
  import DataTable from 'datatables.net-vue3';
  import $ from 'jquery';
  //import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
  import DataTablesCore from 'datatables.net';
  DataTable.use(DataTablesCore);
  
  export default {
    name: 'ReservasComponent',
    components: {
      Navbar,
      LoaderComponent,
      DataTable
    },
    data() {
      return {
        isLoading: true,
        // filtros
        filterDate: new Date().toISOString().split('T')[0], // Formato "Y-m-d"
        filterTime: new Date().toTimeString().split(' ')[0], // Formato "H:i:s"
        // modal
        btn_submit: false,
        title: 'Reporte - Lavado de Vehículos',
        modal: '',
        // form
        id: '',
        name: '',
        // datatable
        items: [],
        items_h: [],
        services: [],
        notification: [],
        columns: [
          {
            title: '#',
            render: function (data, type, row, meta) {
              return meta.row + 1; // Display row index
            },
            width: '3rem'
          },
          {
            title: 'Licencia',
            data: 'license'
          },
          {
            title: 'Fecha de Reserva',
            data: 'created_at',
            render: function (data) {
              if (!data) return 'N/A'; // Manejo de valores nulos o indefinidos
                const date = new Date(data);

                // Obtén año, mes y día, y asegúrate de que el mes y el día tengan dos dígitos
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son base 0, por eso sumamos 1
                const day = String(date.getDate()).padStart(2, '0');

                return `${year}-${month}-${day}`;
            }
          },
          {
            title: 'Fecha de Salida',
            data: 'flight_date'
          },
          {
            title: 'Hora de Salida',
            data: 'arrival_time'
          },
          {
            title: 'Servicios',
            data: 'services',
            render: function (data) {
              if (!data) return 'N/A'; // Manejo de valores nulos o indefinidos
              return data.split(',').join('<br>'); // Reemplaza comas por saltos de línea
            }
          }
        ],
        options: {
          language: {
            url: '//cdn.datatables.net/plug-ins/1.13.6/i18n/es-ES.json',
          },
          responsive: true,
          searching: false, // Deshabilita el campo de búsqueda
          lengthChange: false, // Oculta el control para elegir varios registros
        },
      };
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => {
          // Filtro por fecha de salida
          if (this.filterDate && item.flight_date !== this.filterDate) {
            return false;
          }
          // Filtro por hora de salida
          if (this.filterTime && item.flight_time !== this.filterTime) {
            return false;
          }
          return true;
        });
      }
    },
    mounted() {
      this.sendFilters(); // Ejecuta el método para aplicar filtros y cargar los datos filtrados inicialmente

      // Asegúrate de inicializar la DataTable una vez que el componente esté montado y que tengas la data.
      this.$nextTick(() => {
        if (!$.fn.DataTable.isDataTable('#reporte')) {
          $('#reporte').DataTable(this.options);
        }
      });
  },
  methods: {
    applyFilters() {
      // Este método se ejecutará al cambiar los filtros, si necesitas un filtrado local inmediato.
    },
    async sendFilters() {
      this.isLoading = true;
      try {
        const response = await Api.postData('wash', {
          filter_date: this.filterDate,
          filter_time: this.filterTime,
        });
        
        if (response.response) {
          this.items = response.data;
          console.log(response.data);
          Toast.toast('Filtros Aplicados Correctamente', 'success');

          // Actualiza la tabla
          this.$nextTick(() => {
            const dataTable = $('#reporte').DataTable();
            dataTable.clear().rows.add(this.items).draw();
          });
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error al aplicar filtros: ' + error, 'error');
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
@import 'bootstrap';
@import 'datatables.net-dt';
@import 'datatables.net-bs5';

.card-body {
  background: #fff;
}

.cf {
  margin-top: 6rem !important;
}

.notification{
  border: 1px solid #e9e9e9;
  padding: 1rem;
}
.toast-body{
  cursor: pointer;
}
.nactive {
  background: #e9e9e9;
}

.nowrap {
  white-space: nowrap !important;
}
</style>